<template>
  <form :class="{'was-validated': errors.any()}" @submit.prevent="submit" class="credito" novalidate ref="form">
		<div class="row">
			<div class="title col-sm-12 text-center">
				<h4>Ingresa tus datos</h4>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12">
				<!-- NOMBRE -->
				<div class="row">
					<div class="col-sm-12">
						<input 
							v-model="form.nombre" 
							type="text" 
							name="nombre"
							id="nombre" 
							class="form-control mt-3" 
							placeholder="Nombres"
							v-validate="'alpha_spaces'"
							required
						/>
						<div class="invalid-feedback" style="font-size: .6rem;">{{ errors.first('nombre') }}</div>
					</div>
				</div>

				<!-- APELLIDO PATERNO -->
				<div class="row">
					<div class="col-sm-12">
						<input 
							v-model="form.apellido_paterno" 
							type="text"
							name="apellido_paterno" 
							id="apellido_paterno"
							class="form-control mt-3" 
							placeholder="Apellido paterno"
							data-vv-as="apellido paterno" 
							v-validate="'alpha_spaces'" 
							required
						/>
						<div class="invalid-feedback" style="font-size: .6rem;">{{ errors.first('apellido_paterno') }}</div>
					</div>
				</div>

				<!-- APELLIDO MATERNO -->
				<div class="row">
					<div class="col-sm-12">
						<input 
							v-model="form.apellido_materno" 
							type="text"
							name="apellido_materno" 
							id="apellido_materno"
							class="form-control mt-3" 
							placeholder="Apellido materno"
							data-vv-as="apellido materno" 
							v-validate="'alpha_spaces'" 
							required
						/>
						<div class="invalid-feedback" style="font-size: .6rem;">{{ errors.first('apellido_materno') }}</div>
					</div>
				</div>

				<!-- CURP -->
				<div class="row">
					<div class="col-sm-12">
						<input
							class="form-control mt-3"
							name="curp"
							placeholder="CURP"
							v-model="form.curp"
							type="text"
							data-vv-as="CURP"
							v-validate="'alpha_num|length:18'"
							required
						/>
						<div class="invalid-feedback" style="font-size: .6rem;">{{ errors.first('curp') }}</div>
					</div>
				</div>

				<!-- EMAIL -->
				<div class="row">
					<div class="col-sm-12">
						<input 
							v-model="form.email" 
							type="email" 
							name="email"
							id="email" 
							class="form-control mt-3" 
							placeholder="Email"
							required 
							v-validate 
						/>
						<div class="invalid-feedback" style="font-size: .6rem;">{{ errors.first('email') }}</div>
					</div>
				</div>

				<!-- TELEFONO -->
				<div class="row">
					<div class="col-sm-12">
						<input 
							v-model="form.celular" 
							type="text" 
							name="celular"
							id="celular" 
							class="form-control mt-3" 
							placeholder="Celular"
							data-vv-as="celular" 
							required
							v-validate="'numeric|min:10|max:16'" 
						/>
						<div class="invalid-feedback" style="font-size: .6rem;">{{ errors.first('celular') }}</div>
					</div>
				</div>

				<!-- DESARROLLO -->
				<div class="row mt-2">
					<div class="col-sm-12">
						<label for=""><small class="text-muted">Desarrollo en el que estas interesado</small></label>
						<select v-model="form.desarrollo_id" name="desarrollo" required v-validate class="form-select">
							<option :value="desarrollo.id" v-for="desarrollo in desarrollos" :key="desarrollo.id">{{ desarrollo.development }}</option>
						</select>
						<div class="invalid-feedback">{{ errors.first('desarrollo') }}</div>						
					</div>
				</div>

			</div>
		
			<div class="col-sm-12">

				<!-- POLITICAS DE PRIVACIDAD -->
				<div class="row form-check">
					<div class="col-sm-12 mt-4">
						<input 
							v-model="form.politicas" 
							type="checkbox"
							name="politicas" 
							id="politicas" 
							class="form-check-input"
							required 
							v-validate 
						/>
						<label class="form-check-label" for="politicas">
							Acepto las <a data-bs-toggle="modal" data-bs-target="#privacyNoticeModal"><ins>políticas de privacidad</ins></a>
						</label>
						<div class="invalid-feedback" style="font-size: .6rem;">{{ errors.first('politicas') }}</div>
					</div>
				</div>

			</div>
		</div>

		<div class="row mt-3">
			<div class="col-sm-12 text-center">
				<button id="obtenerCredito" type="submit">QUIERO MI CASA</button>
			</div>
		</div>

		<div class="row">
			<div class="col-sm-12 text-center mt-3">
				¡Un socio promotor te contactará!
			</div>
		</div>

		<!-- modal aviso de privacidad -->
		<PrivacyNoticeModal />

	</form>
</template>

<script>
import PrivacyNoticeModal from './PrivacyNoticeModal.vue';
import OrdenesAPI from '@/api/ordenes.api'
export default {
	data() {
		return {
			form: {
				nombre: null,
				apellido_paterno: null,
				apellido_materno: null,
				email: null,
				curp: null,
				desarrollo_id: null
			},
			desarrollos: [] // Llenado desde Desarrollos.vue@loadDesarrollos
		}
	},

	components: { 
		PrivacyNoticeModal, 
	},

	methods: {
		async submit() {
			if (! (this.$validator && (await this.$validator.validateAll()))) {
				return;
			}

			const loading = this.$loading.show({ container: this.$refs.form })
			try {
				const response = await OrdenesAPI.create(this.form)

				this.clear()
				this.$swal({
					type: 'success',
					title: 'Éxito',
					position: 'center',
					text: '¡Un socio promotor te contactará!',
					confirmButtonColor: '#24b361',
				})
			} finally {
				loading.hide()
			}
		},
		clear() {
			this.form = {
				nombre: null,
				apellido_paterno: null,
				apellido_materno: null,
				email: null,
				curp: null,
				desarrollo_id: null
			}
			this.$validator.reset()
		}
	}
}
</script>