<template>
  <div id="desarrollos" class="full-container" style="margin-top:4em">
    <div class="container">
      <div class="row">
        <div class="col-sm-12" v-if="properties.length > 0" ref="desarrollos">
          <h3>DESARROLLOS</h3>
          <div class="offset-md-3 col-md-6">
            <h5 class="text-center">Descubre tu próxima inversión</h5>
            <select class="form-select" aria-label="Default select example" v-model="filter">
              <option :value="null">Todos</option>
              <option :value="state" v-for="state in states" :key="state">{{ state }}</option>
            </select>
          </div>
          <br />

          <!-- Desarrollos -->
          <div class="d-flex flex-wrap">
            <div v-for="currentProperty in showProperties" :key="currentProperty.id" class="col-md-3 col-sm-6">
              <div class="card m-2">
                <img
                  :src="currentProperty.image_facade"
                  class="card-img-top"
                  alt="Casa muestra"
                  height="200px"
                  max-width="100px"
                />
                <div class="card-body">
                  <span class="text-small">Desde:</span>
                  <h4 class="card-title" v-if="isNaN(currentProperty.prices_between.price_from)">
                    {{ currentProperty.prices_between.price_from }}
                  </h4>
                  <h4 class="card-title" v-else>
                    {{ currentProperty.prices_between.price_from | currency }}
                  </h4>
                  <p class="card-text">
                    <small class="text-muted" v-html="currentProperty.description"></small>
                  </p>
                  <div class="card-text">
                    <h6><strong>{{ currentProperty.development }}</strong></h6>
                    <span class="text-small text-muted">{{
                      currentProperty.state.state
                    }}</span>
                  </div>
                  <a href="#" class="btn btn-primary" @click.prevent="selectDesarrollo(currentProperty)">Seleccionar</a>
                </div>
              </div>
            </div>
          </div>
          <!-- end Desarrollos -->

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SadasiAPI from '@/api/sadasi.api'
export default {
  data() {
    return {
      properties: [],
      filter: null,
    };
  },
  computed: {
    showProperties() {
      return this.properties
        .filter((property) => this.filter === null ? true : property.state.state === this.filter)
    },

    states() {
      return [...new Set(
        this.properties.map((property) => property.state.state)
      )].sort()
    }
  },

  mounted() {
    this.loadDesarrollos()
  },

  methods: {
    async loadDesarrollos() {
      const loading = this.$loading.show({ container: this.$refs.desarrollos })
      try {
        this.properties = await SadasiAPI.developments()

        const component = this.$parent.$refs.hero.$refs.form
        component.desarrollos = this.properties
      } finally {
        loading.hide()
      }
    },

    selectDesarrollo(desarrollo) {
      const component = this.$parent.$refs.hero.$refs.form

      component.form.desarrollo_id = desarrollo.id
      component.$el.scrollIntoView()
    }
  }
};
</script>
