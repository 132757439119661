<template>
  <div class="hero">
			<div id="credito" class="credito-info">
				<div class="row">
					<div class="col-md-6 subtitle">
            <h1>
              Adquiere tu casa ahora. <br />
            Bien para Bien<br> en alianza con SADASI
            </h1>
					</div>
					<div class="col-md-6">
					<div id="registro" class="container">
						<Form ref="form" />
					</div>
					</div>
				</div>
			</div>
    </div>
</template>

<script>
import Form from './Form.vue'
export default {
	components: { 
    Form 
  }
}
</script>