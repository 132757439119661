<template>
  <div id="app">
    <Header />
    <div class="content">
      <Main />
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Main from './components/Main.vue'

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Main,
  }
}
</script>

<style lang="scss">
@import 'assets/styles/main';
</style>
